.services-section {
    width: 100%;
    background-color: #f7f9fc;
    padding: 40px 0;
}

.services-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
}

.services-container h2 {
    text-align: center;
    color: #033161;
    font-size: 3rem;
    margin-bottom: 50px;
    position: relative;
}

.services-container h2::after {
    content: "";
    display: block;
    width: 220px;
    height: 4px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 10px;
    background-color: #2A7FFD;
    box-shadow: 0px 6px 12px 0px #000;
}



.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 30px;
    justify-items: center;
}


.service-card {
    padding: 20px;
    border: 1px solid #ddd;
    text-align: center;
    border-radius: 8px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    transform: translateZ(0); /* Helps with smoother scaling */
}


.service-card:hover {
    transform: scale(1.02);
    box-shadow: 0px 8px 16px rgba(6, 22, 250, 0.3); /* Slightly lighter shadow */
    background: linear-gradient(130deg, #effafc 0%, #effafc 100%);
    cursor: pointer;
}

.service-icon {
    width: 60px;
    height: 30%;
    margin-bottom: 10px;
    transition: transform 0.9s ease;
}


.service-card:hover .service-icon {
    transition: 0.9s;
    /* transform: rotate(360deg); */
    animation: rotateIcon 0.9s ease forwards;
}

/* Define the keyframe animation */
@keyframes rotateIcon {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}



.service-card h3 {
    font-size: 1.4rem;
    color: #333;
    margin-bottom: 10px;
}

.service-card p {
    font-size: 19px;
    color: #666;
    margin-bottom: 15px;
}

button {
    background-color: #007bff;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

@media (max-width: 768px) {
    .services-container h2 {
        font-size: 2.5rem;
    }

    .service-card {
        padding: 15px;
    }

    h3 {
        font-size: 1rem;
    }

    p {
        font-size: 0.85rem;
    }
}


