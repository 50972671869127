
.blog-section {
    width: 100%;
    background-color: #f8f9fa;
    padding: 65px 20px;
}

.blog-container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
}

.blog-title {
    font-size: 2.5rem;
    color: #033161;
    margin-bottom: 20px;
    font-weight: 600;
    position: relative;
    padding-bottom: 40px;
}

.blog-title::after {
    content: "";
    display: block;
    width: 220px;
    height: 4px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 10px;
    background-color: #2A7FFD;
    box-shadow: 0px 6px 12px 0px #000;
}

.blog-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.blog-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    width: 100%;
    max-width: 350px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: left;
    transition: transform 0.5s ease;
}

.blog-card:hover {
    transform: scale(1.02);
}

.blog-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.blog-card-title {
    font-size: 1.2rem;
    margin: 16px;
    font-weight: 600;
}

.blog-card-text {
    font-size: 0.9rem;
    color: #555;
    margin: 0 16px 16px;
}

.blog-read-more {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 16px;
    margin: 16px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 0.9rem;
    transition: background-color 0.3s ease;
}

.blog-read-more:hover {
    background-color: #0056b3;
}

/* Responsive Styling */
@media (max-width: 768px) {
    .blog-cards {
        flex-direction: column;
        align-items: center;
    }
}

@media (max-width: 425px){
    .blog-title::after {
        width: 220px;
    }
}
