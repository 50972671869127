/* Company Page Banner section */
.private-limited-banner-section {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.private-limited-banner-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;    /* Prevents extra space below the image */
}

/* ============== Company Page Content Section ============= */
.private-limited-page-section {
    display: flex;
    justify-content: center;
    padding: 80px 20px; /* Adds padding around the content */
    box-sizing: border-box;
}

.private-limited-page-content {
    max-width: 1100px;
    width: 100%;
    font-size: 1rem; /* Adjust font size as needed */
    line-height: 1.6;
    color: #333; /* Adjust text color */
}

.private-limited-page-content h2 {
    font-size: 35px;
}

/* Optional Styling */
.private-limited-page-content p {
    margin-bottom: 20px;
    font-size: 20px;
}

.private-limited-page-content p ol {
    margin-left: 30px;
}

.private-limited-page-content p ul {
    margin-left: 30px;
}