/* HeroBanner.css */

.hero-banner-section {
    width: 100%;
    /* min-height: 100vh; */
    /* min-height: 600px; */
    min-height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    
}

.hero-banner-container {
    /* max-width: 1200px;  as banner view will be full so we would not required 1200 otherwise it will take space from left and right*/  
    width: 100%;      
    height: 100%;
    position: relative;
}

.banner-image {
    width: 100%;
    /* height: 100vh; */
    /* min-height: 600px; */
    object-fit: cover;
    transition: transform 0.5s ease-in-out;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
}

.left-arrow {
    left: 20px;
}

.right-arrow {
    right: 20px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .arrow {
        padding: 8px;
        font-size: 20px; 
    }

    
}
