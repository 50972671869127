.reviews-section {
    width: 100%;
    background-image: url('../../images/customer-review-1.jpg');
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    position: relative;
    padding: 100px 0;
}

.reviews-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
}

.reviews-container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
    z-index: 2;
    text-align: center;
}

/* Adjusted arrow positioning */
.arrow-icon {
    font-size: 2.5rem;
    color: #fff;
    cursor: pointer;
    transition: color 0.3s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.arrow-left {
    left: -20px; /* Adjust as needed to position arrow */
}

.arrow-right {
    right: -20px; /* Adjust as needed to position arrow */
}

.arrow-icon:hover {
    color: #ddd;
}

.review-content {
    font-size: 1.3rem;
    position: relative;
    margin: 20px auto;
    padding: 0 40px; /* Additional padding for better alignment */
    color: #fff;
    max-width: 900px; /* Restrict width for better readability */
}

.review-content p{
    margin: 0 20px;
}

.quote-icon {
    font-size: 2.5rem;
    color: #fff;
}

.quote-icon.left {
    position: absolute;
    top: -20px;
    left: 0;
}

.quote-icon.right {
    position: absolute;
    bottom: -20px;
    right: 0;
}

.review-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-top: 30px; */
}

.review-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 15px;
    object-fit: cover;
    border: 2px solid #fff;
}

.review-details h3 {
    font-size: 2.5rem;
    /* margin: 10px 0; */
}

.position {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    margin-top: 10px;
}

.navigation-arrows {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}

.arrow-icon {
    font-size: 1.8rem;
    color: #fff;
    cursor: pointer;
    margin: 0 15px;
    transition: color 0.3s;
}

.arrow-icon:hover {
    color: #ddd;
}

.review-indicators {
    display: flex;
    justify-content: center;
    margin-top: 80px;
}

.indicator {
    width: 30px;
    height: 4px;
    background-color: #777;
    margin: 0 5px;
    transition: background-color 0.3s;
}

.indicator.active {
    background-color: #fff;
}


@media (max-width: 1024px){
    .review-content p{
        margin: 40px 40px;
    }

    .arrow-icon{
        margin: 0 40px;
    }
}


@media (max-width: 768px){
    .review-content p{
        margin: 40px 20px;
        font-size: 18px;
    }

    
}



@media (max-width: 425px){
    .review-details{
        margin-bottom: 100px;
    }

    .review-content p{
        margin: 40px 0px;
        font-size: 16px;
    }

    .quote-icon{
        font-size: 2rem;
    }

    
}