.experience-section {
    width: 100%;
    background-color: #007bff;
    padding: 80px 0;
}

.experience-container {
    max-width: 1200px;
    margin: 0 auto;
    /* padding: 0 20px; */
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    justify-items: center;
}

.experience-card {
    width: 270px;
    /* Fixed width for consistent card sizing */
    background-color: #f1f5fc;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    transition: transform 0.5s ease, box-shadow 0.3s ease;
    transform: translateY(-20px);
    /* Initial position for animation */
    opacity: 0;
    /* Initial opacity for animation */
}

.experience-card.animate {
    transform: translateY(0);
    /* Drop into place */
    opacity: 1;
    transition: transform 0.5s ease, opacity 0.5s ease;
}

.experience-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon-wrapper {
    width: 60px;
    height: 60px;
    /* height: auto; */
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #fff; */
    background-color: #007bff;
    border-radius: 50%;
    margin: -50px auto 10px;
    border: 5px solid #fff;
}

.icon-wrapper svg {
    /* color: #007bff; */
    color: #fff;
    font-size: 1.8rem;
}

.count {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin: 10px 0;
    position: relative;
}

.count::after {
    content: '';
    display: block;
    width: 30%;
    /* Adjust width as needed */
    height: 2px;
    /* Thickness of the underline */
    background-color: #8dc6c9;
    /* Color of the underline */
    margin: 8px auto 0;
}

.experience-card p {
    font-size: 1.5rem;
    color: #033161;
    margin-top: 17px;
}

/* ============ ============ Responsive adjustments ========== =================== */

@media (max-width: 1024px) {
    .experience-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 80px 0px;
    }

    
}


@media (max-width: 768px) {
    .experience-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: 50px 0px;
    }
}



@media (max-width: 425px) {
    .experience-container {
        display: grid !important;
        grid-template-columns: 1fr;
        /* Force a single column */
        gap: 50px;
        /* Vertical gap between cards */
    }
}