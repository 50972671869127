.about-home-section {
    width: 100%;
    padding: 60px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
}

.about-home-heading {
    font-size: 43px;
    font-weight: bold;
    /* color: #333; */
    /* color: #007bff; */
    /* color: #021b6b; */
    color: #033161;
    margin-bottom: 40px;
    text-align: center;
    width: 100%;
}

.about-home-heading::after {
    content: "";
    display: block;
    width: 480px;
    height: 4px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 10px;
    background-color: #2A7FFD;
    box-shadow: 0px 6px 12px 0px #000;
}

.about-home-container {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    gap: 130px;
    align-items: center;
    justify-content: space-between;
}



.about-text {
    flex: 1;
    max-width: 600px;
}

.about-text h2 {
    font-size: 32px;
    color: #333;
    /* color: #033161; */
    /* color: #033161; */
    margin-bottom: 10px;
}

.about-text p {
    color: #555;
    line-height: 1.6;
    margin-bottom: 15px;
    font-size: 19px;

}

.progress-bars {
    margin: 20px 0;
}

.progress-bar {
    margin-bottom: 15px;
}

.progress-bar span {
    font-weight: bold;
    color: #333;
    display: inline-block;
    margin-bottom: 5px;
}

.progress {
    width: 100%;
    height: 20px;
    background-color: #e0e0e0;
    border-radius: 6px;
    overflow: hidden;
    position: relative;
}

.progress-fill {
    height: 100%;
    color: white;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    border-radius: 6px 0 0 6px;
}

/* Individual colors for each progress bar */
.progress-fill.knowledge {
    background-color: #007bff; /* Blue */
}

.progress-fill.experience {
    /* background-color: #17a2b8;  */
    background-color: rgb(255, 187, 0); /* Teal */
}

.progress-fill.friendly-team {
    /* background-color: #28a745;  */
    background-color: #058605; 
}

.progress-fill.confidentiality {
    background-color: #dc3545; /* Red */
}

.about-image {
    flex: 1;
    max-width: 400px;
}

.about-image img {
    width: 100%;
    border-radius: 8px;
}

.read-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    border: 2px solid #007bff;
    background: transparent;
    color: #007bff;
    font-weight: bold;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
}

.read-more-btn:hover {
    background-color: #007bff;
    color: #fff;
}



@media (max-width: 768px) {
    .about-home-container {
        gap: 30px;
    }

    
}


/* Mobile-specific styles for 425px and below */
@media (max-width: 425px) {
    .about-home-container {
        gap: 20px;
        flex-direction: column; /* Stack elements vertically */
        align-items: center;
    }

    .about-image {
        order: 2; /* Move the image to the bottom */
        max-width: 100%; /* Full-width on mobile */
        margin-top: 20px; /* Add some spacing above the image */
    }

    .about-text {
        order: 1; /* Keep the text above the image */
        max-width: 100%; /* Full-width on mobile */
    }

    .about-home-heading {
        font-size: 28px; /* Slightly smaller heading for smaller screens */
    }

    .about-text h2 {
        font-size: 24px;
    }

    .about-text p {
        font-size: 16px;
    }

    .about-home-heading {
        font-size: 2.5rem;
    }

    .about-home-heading::after {
        width: 290px;
    }

    .about-text h2 {
        font-size: 20px;
    }
}





