
.footer-section {
    width: 100%;
    background: url("../../images/footer-bg-1.jpg") no-repeat center center;
    background-size: cover;
    color: #fff;
    padding: 40px 20px;
    padding-bottom: 10px;
    position: relative;
    z-index: 0;
    border-radius: 20px 20px 0px 0px;
}

.footer-section::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 123, 255, 0.9); /* This is the correct format */
    z-index: 1;
    border-radius: 20px 20px 0px 0px;
}

.footer-container, .footer-bottom {
    position: relative;
    z-index: 2;
}

.footer-container {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    text-align: left;
}

.footer-column {
    flex: 1 1 200px;
    min-width: 180px;
}

.footer-column h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
    font-weight: 600;
    /* background-color: rgba(255, 255, 255, 0.2); */
    background-color: rgba(255, 255, 255, 0.6);
    padding: 3px 3px;
    border-radius: 3px;
    display: inline-block;
    color: #000; /* Set the text color */
    text-transform: uppercase;
}

.footer-column p {
    font-size: 1rem;
    line-height: 1.5;
    /* color: #d1d1d1; */
    color: #fff;
    margin-right: 10px;
}

.footer-column ul {
    list-style: none;
    padding: 0;
    font-size: 1rem;
}



.footer-column ul li {
    margin-bottom: 8px;
    color: #fff;
    margin-left: 5px;
    
}

.footer-column ul li a {
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Makes the link color inherit from parent li color (or customize if needed) */
    /* display: flex; */
    /* align-items: center; */
}

.footer-column ul li a svg {
    margin-top: 2px; /* Adjust this value as needed to move the icons down */
    margin-right: 5px;
}

/* Hover effect for links */
.footer-column ul li a:hover {
    background-color: rgba(255, 255, 255, 0.3);
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 25px;

}


.services-list {
    list-style: none;
    padding: 0;
    column-count: 2; /* Only applies to Our Services */
    column-gap: 20px; /* Adjust the gap between columns if needed */
    margin-left: -45px;
}

.services-list-heading{
    margin-left: -45px;
}

.services-list li {
    margin-bottom: 8px;
    color: #fff;
    break-inside: avoid; /* Prevents items from splitting between columns */
}

.footer-bottom {
    text-align: center;
    font-size: 1rem;
    margin-top: 35px;
    color: #fff;
    /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
    border-top: 1px solid rgba(255, 255, 255, 0.425);
    padding-top: 13px;
}

.footer-bottom a {
   text-decoration: none;
   color: inherit;
   font-weight: 600;
}



/* Responsive Styling */



@media (max-width: 425px) {
    /* Center-align all footer columns and text */
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    /* Set each footer column to take full width */
    .footer-column {
        width: 100%;
        max-width: 300px;
        margin-bottom: 20px;
    }

    /* Center "Our Services" heading and remove the left margin */
    .services-list-heading {
        margin-left: 0;
        text-align: center;
    }

    /* Center-align the list and ensure it displays in one column */
    .services-list {
        column-count: 1; /* Display in one column on small screens */
        margin-left: 0;
    }

    /* Optional: Adjust spacing between list items if needed */
    .services-list li {
        margin-bottom: 10px;
    }

    /* Move "Website Developed by Pervez Alam" to a new line */
    .footer-bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-bottom span {
        margin-top: 5px; /* Adds spacing between the copyright text and the developer credit */
        display: block; /* Ensures the developer credit appears on a new line */
    }
}