.faq-section {
    width: 100%;
    padding: 70px 20px;
    background-color: #f9f9f9;
}

.faq-container {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.faq-container h2 {
    text-align: center;
    color: #033161;
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
    position: relative;
    padding-bottom: 40px;;
}


.faq-container h2::after {
    content: "";
    display: block;
    width: 420px;
    height: 4px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 10px;
    background-color: #2A7FFD;
    box-shadow: 0px 6px 12px 0px #000;
}

.faq-item {
    border-top: 1px solid #ddd;
    padding: 15px;
    cursor: pointer;
    transition: background 0.3s;
    margin: 0 70px;
}

.faq-item:hover {
    background-color: #f0f8ff;
}

/* Persistent background color for active item */
.faq-item.active {
    background-color: #f0f8ff;
}

.faq-item.active .faq-question {
    font-weight: bold;
    color: #007bff;

}

.faq-question {
    font-size: 1.3rem;
    /* font-weight: 600; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq-answer {
    padding: 10px 0;
    font-size: 1.1rem;
    line-height: 1.6;
    white-space: pre-line; /* Keeps line breaks in the answer text */
}


@media (max-width: 425px){
    .faq-item{
        margin: 0 10px;
    }

    .faq-container h2::after {
       
        width: 220px;
        
    }
    
}