.features-section {
    width: 100%;
    /* display: flex; */
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 60px 20px;
    background-color: #f9fafb; /* Light background color */
}

.features-container {
    max-width: 1200px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
}

.feature-card {
    background-color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
    transition: transform 0.6s ease, box-shadow 0.8s ease;
    position: relative;
    /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); */
}

.feature-card:hover {
    transform: translateY(-10px);
    /* box-shadow: 0 10px 20px rgba(0, 0, 0, 0.35); */
    /* box-shadow: 0 10px 20px rgba(108, 156, 136, 0.55); */
    box-shadow: 0px 4px 13px 8px rgba(59, 130, 246, 0.15);
    
}

.icon-circle {
    width: 65px;
    height: 65px;
    background-color: rgba(0, 150, 136, 0.1); /* Light green background */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 15px;
}

.feature-icon {
    font-size: 40px;
    color: #009688; /* Icon color */
    opacity: 0.8;
}

.feature-card h3 {
    font-size: 25px;
    margin: 10px 0;
    color: #333;
}

.feature-card p {
    font-size: 19px;
    color: #666;
}


@media (max-width: 1024px) {
    .features-container {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }

    .feature-card h3 {
        font-size: 20px;
    }

    .feature-card p{
        font-size: 16px;
    }
}

@media (max-width: 768px){
    .features-container {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    .feature-card h3 {
        font-size: 25px;
    }

    .feature-card p{
        font-size: 19px;
    }
}