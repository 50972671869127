/* Banner section */
.contact-us-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    height: 400px; /* Sets the height of the banner */
}

.contact-banner-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;    /* Prevents extra space below the image */
}


/* ============== Great Experiences section ============= */
.great-experiences-section {
    background-color: #f3e7e1;
    padding: 40px 20px;
    text-align: center;

}

.great-experiences-section h3 {
    font-size: 3.2rem;
    margin-bottom: 10px;
    color: #033161;
    /* margin: 0 40px; */
    max-width: 920px;
    margin: 0 auto;
    padding-top: 20px;

}

.great-experiences-section p {
    max-width: 920px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 1.5rem;
    /* color: #666; */
    color: #000;
}


/* ============== Contact Information Section ============= */
.contact-info-section {
    text-align: center;
    margin-top: 40px;
    padding: 40px 0;
}

.contact-info-section h2 {
    font-size: 3.2rem;
    color: #033161;
    margin-bottom: 10px;
}

.contact-info-section p {
    color: #666;
    font-size: 1.2rem;
    margin-bottom: 30px;
}

.contact-cards {
    display: flex;
    justify-content: center;
    background-color: #033161; /* Matches the attached image background color */
    max-width: 1200px;
    margin: 45px auto;
    padding: 40px 20px;
    border-radius: 8px;
    gap: 45px;
}

.contact-card {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 33px;
    margin: 10px;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-icon {
    font-size: 2.5rem;
    color: #0066cc;
    margin-right: 15px;
    flex-shrink: 0; /* Prevents icon from shrinking */
}

.contact-card p {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
    text-align: left !important;

}


/* ============== Contact Form Section ============= */


.contact-form-section {
    width: 100%;
    /* min-height: 100vh; */
    background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
    /* background-size: contain; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding: 50px 20px;
}

.contact-form-container {
    width: 80%;
    max-width: 1200px;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
}

.contact-form-container h2 {
    font-size: 3.2rem;
    margin-bottom: 50px;
    color: #033161;

}

.contact-us-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.contact-us-form-row {
    display: flex;
    gap: 20px;
}


/* Ensure consistent width for all input fields and select elements */
.contact-us-form-row input,
.contact-us-form-row select {
    flex: 1;
    padding: 12px;
    font-size: 1rem;
    /* border: 1px solid #ccc; */
    border: 1px solid #033161;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Increase message box height */
.contact-us-form textarea {
    resize: none;
    padding: 12px;
    font-size: 1rem;
    /* border: 1px solid #ccc; */
    border: 1px solid #033161;
    border-radius: 4px;
    box-sizing: border-box;
    height: 130px; /* Adjust the height as needed */
}


/* Ensure CAPTCHA row inputs are aligned properly */
.contact-us-captcha-row {
    display: flex;
    gap: 20px;
    width: 100%;
    flex: 1;
}

.contact-us-captcha-row input {
    flex: 1;
}

.captcha-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 1.5rem;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100px;
}

.contact-us-form button {
    width: 120px; /* Adjusted width for a shorter button */
    padding: 12px;
    font-size: 1.1rem;
    color: #fff;
    /* color: #000; */
    /* background-color: #007BFF; */
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin: 0 auto;
}

.contact-us-form button:hover {
    /* background-color: #0056b3; */
    background-color: #0056b3;
}


.message {
    font-size: 1.1em;
    font-weight: bold;
    padding: 8px 12px;
    margin-top: 10px;
    display: inline-block;
    width: auto;
    border-radius: 5px;
}

/* Form Delivery messages */
.success {
    color: #2f6627;
    background-color: #d4edda;
}

.error {
    color: #721c24;
    background-color: #f8d7da;
}

/* ============== Contact Us Map Section ============= */
.contact-us-map-section {
    width: 100%;
    max-width: 1200px;
    margin: 50px auto;
    padding: 20px;
    box-sizing: border-box;
    border: 2px solid #033161; /* Border color matches theme */
    border-radius: 8px;
    overflow: hidden;
}

.contact-us-map-section iframe {
    width: 100%;
    height: 450px;
    border: none; /* Removes default iframe border */
    border-radius: 8px; /* Adds rounded corners */
}


@media (max-width: 768px) {

    /* Ensure full width and height for the banner section */
    .contact-us-banner {
        width: 100vw; /* Use viewport width */
        height: auto; /* Adjust height based on content */
        margin: 0;
        padding: 0;
    }

    .contact-banner-image img {
        width: 100%;
        height: 100%; /* Make sure image fills the banner section */
        object-fit: cover; /* Cover the container without gaps */
        display: block;
    }

    .contact-cards {
        flex-direction: row; /* Stack cards vertically */
        gap: 20px; /* Adjust the gap between cards */
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .contact-card {
        flex-direction: column; /* Stack icon and content vertically */
        align-items: center; /* Center-align for better appearance */
        width: 100%; /* Full-width on smaller screens */
        padding: 20px;
        text-align: center;
    }

    .contact-icon {
        margin-right: 0; /* Remove right margin for centered alignment */
        margin-bottom: 15px; /* Add bottom margin to separate icon from content */
    }

    .contact-card p {
        text-align: center !important; 
        font-size: 1.4rem;
    }

    .contact-us-form-row {
        flex-direction: column;
    }
}


@media (max-width: 425px) {

    .great-experiences-section h3{
        font-size: 2.4rem;
    }

    .contact-info-section h2{
        font-size: 2.4rem;
    }

    .contact-info-section p{
        margin: 0 10px;
    }

    .contact-cards {
        flex-direction: column; /* Stack cards vertically */
        gap: 20px; /* Adjust the gap between cards */
        padding: 20px; /* Reduce padding for smaller screens */
    }

    .contact-card {
        flex-direction: column; /* Stack icon and content vertically */
        align-items: center; /* Center-align for better appearance */
        width: 100%; /* Full-width on smaller screens */
        padding: 20px;
        text-align: center;
    }

    .contact-icon {
        margin-right: 0; /* Remove right margin for centered alignment */
        margin-bottom: 15px; /* Add bottom margin to separate icon from content */
    }

    .contact-card p {
        text-align: center !important; 
        font-size: 1.4rem;
    }



    /* Adjust the contact form container */
    .contact-form-section{
        padding: 38px 20px;
    }

    .contact-form-container {
        width: 90%; /* Slightly smaller to allow padding space */
        padding: 20px;
    }

    .contact-form-container h2{
        font-size: 2.2rem;
    }

    /* Stack all elements in the contact form vertically */
    .contact-us-form {
        gap: 20px;
    }

    /* Stack form rows vertically */
    .contact-us-form-row {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    /* Stack CAPTCHA row vertically */
    .contact-us-captcha-row {
        flex-direction: column;
        gap: 10px;
        width: 100%;
    }

    /* Ensure full width for input fields in CAPTCHA */
    .captcha-box,
    .contact-us-captcha-row input {
        width: 100%;
    }

    /* Responsive adjustments for inputs and select dropdown */
    .contact-us-form-row input,
    .contact-us-form-row select,
    .contact-us-form textarea {
        width: 100%; /* Full width on mobile */
    }
    
}


@media (max-width: 375px){
    .contact-form-container h2 {
        font-size: 2rem;
    }
}