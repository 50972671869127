/* Banner section */
.about-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
}

.about-banner-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    display: block;
    /* Prevents extra space below the image */
}

/* ============== Who We Are section ============= */
.about-content {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
    text-align: center;
}

.about-content h2 {
    font-size: 2.5rem;
    color: #0d3b66;
    font-weight: bold;
    margin-bottom: 10px;
    padding-top: 20px;

}

.about-content h2::after {
    content: "";
    display: block;
    width: 200px;
    height: 4px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 10px;
    background-color: #2A7FFD;
    box-shadow: 0px 6px 12px 0px #000;
}

.about-content p {
    font-size: 1.2rem;
    color: #333;
    line-height: 1.6;
    max-width: 1140px;
    margin: 0 auto;
    text-align: left;
    /* margin-bottom: 1rem; */
    margin: 2.5rem;
}

/* Financial Activities Section */
.financial-activities-section {
    position: relative;
    text-align: center;
    color: white;
}

.financial-activities-section img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    filter: brightness(0.9);
    padding-top: 40px;
    display: block;
    /* Prevents extra space below the image */
}

.financial-activities-text {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
    /* Control the width of the text container, adjust as needed */
    max-width: 900px;
    /* Optional, to limit maximum width */
    text-align: center;
}

.financial-activities-text h1 {
    font-size: 4rem;
    font-weight: bold;
    line-height: 1.3em;
}


/* Understanding Your Finances Section */
.finances-info-section {
    background-color: #f3e7e1;
    padding: 40px 20px;
    text-align: center;

}

.finances-info-section h3 {
    font-size: 2.2rem;
    margin-bottom: 10px;
    color: #033161;
    /* margin: 0 40px; */
    max-width: 920px;
    margin: 0 auto;
    padding-top: 20px;

}

.finances-info-section p {
    max-width: 920px;
    margin: 0 auto;
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 1.5rem;
    /* color: #666; */
    color: #000;
}


/* What We Do Section */
.what-we-do-section {
    padding: 60px 20px;
    text-align: center;
}

.what-we-do-section h1 {
    font-size: 2.5rem;
    color: #0d3b66;
    margin-bottom: 20px;
    /* display: inline-block; */
}

.what-we-do-section h1::after {
    content: "";
    display: block;
    width: 200px;
    height: 4px;
    position: absolute;
    border-radius: 3px;
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 10px;
    background-color: #2A7FFD;
    box-shadow: 0px 6px 12px 0px #000;
}

.what-we-do-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
    /* Allows wrapping on smaller screens */
}

.what-we-do-text {
    flex: 1;
    max-width: 600px;
    text-align: left;
    padding: 20px;
}

.what-we-do-text h2 {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 10px;
}

.what-we-do-text p {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
}

.what-we-do-image {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 500px;
    padding-top: 20px;
}

.what-we-do-image img {
    width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}




/* Core Values Section */
.core-values-section {
    width: 100%;
    padding: 60px 20px;
    background-color: #f9f9f9;
    text-align: center;
}

.core-values-section h2 {
    font-size: 40px;
    color: #0d3b66;
    margin-bottom: 55px;
}

.core-values-cards {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

/* Core Value Card */
.core-value-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.5s ease, box-shadow 0.3s ease;
}

.core-value-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);

}


/* Icon Styling */
.icon-size {
    height: 80px;
    margin: 0 auto 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.9s ease;
    /* Add transition to icon */
}


.core-value-title {
    font-size: 24px;
    color: #0d3b66;
    margin: 10px 0;
}

.core-value-description {
    font-size: 18px;
    color: #000;
    line-height: 1.6;
}



.core-value-card:hover .icon-size {
    /* transform: rotate(360deg); */
    transition: 0.9s;
    animation: rotateAboutIcon 0.9s ease forwards;
}


/* Define the keyframe animation */
@keyframes rotateAboutIcon {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}



/* Responsive adjustments */
@media (max-width: 768px) {

    .about-content h2 {
        font-size: 2.5rem;
    }

    .about-content p {
        font-size: 0.9rem;
    }

    .financial-activities-section img {
        height: 250px;
    }

    .financial-activities-section h2,
    .finances-info-section h3,
    .what-we-do-section h2 {
        font-size: 1.5rem;
    }

    .core-values-section h2 {
        font-size: 2rem;
    }

    .what-we-do-content {
        flex-direction: column;
    }

    .core-values-cards {

        grid-template-columns: repeat(2, 1fr);
        /* gap: 30px; */
    }

    .financial-activities-text h1 {
        font-size: 3rem;
    }

    /* .core-value-card {
        width: 100%;
    } */
}

@media (max-width: 425px) {
    .core-values-cards {
        grid-template-columns: 1fr;
        /* Display 1 card per row */
        gap: 20px;
        /* Adjust gap as needed */
        padding: 0 10px;
        /* Optional: add padding for better alignment on small screens */
    }

    .financial-activities-text h1 {
        font-size: 2.2rem;
    }

    .finances-info-section p {
        font-size: 1.3rem;
    }
}