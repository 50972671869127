/* Overall navbar styling */
.navbar-section {
    width: 100%;
    /* background-color: #f8f9fa; */
    background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
    /* background-image: linear-gradient(to top, #dfe9f3 0%, white 100%); */
    /* background: linear-gradient(to right, #dcfce7,  #e0f2fe); */
    /* background-image: linear-gradient(-225deg, #FFFEFF 0%, #D7FFFE 60%); */
    /* background-color: rgba(0, 150, 136, 0.1); */
    display: flex;
    justify-content: center;
    padding: 10px 0;
    /* height: 10vh; */
    position: relative;
}

.navbar-container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Left part - Logo */
.navbar-logo {
    display: flex;
    align-items: center;
}

.navbar-logo img {
    height: 60px;
    width: auto;
}



/* Center part - Links */
.navbar-links {
    display: flex;
    gap: 30px;
    margin-left: auto;
    margin-right: 40px;
    /* Ensures links stay centered */
    /* align-items: center;  */

}


.navbar-links a {
    color: #1c1e21;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 500;
}

.navbar-links a:hover {
    color: #007bff;
}

/* Dropdown styling */
.dropdown {
    position: relative;
    cursor: pointer;
    /* padding: 30px 0; */
}

.dropdown:hover::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 30px; /* Adjust based on the gap size */
    background: transparent;
}


.dropdown>a {
    /* Adds vertical padding to align with other links */
    /* padding: 30px 0; */
    font-size: 1.1rem;
    font-weight: 500;
    color: #1c1e21;
   
}

.dropdown-menu {
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 0;
    display: none;
    flex-direction: column;
    min-width: 200px;
    z-index: 100;
    white-space: nowrap;
    text-transform: uppercase;
}

.dropdown-link span {
    font-size: 13px;
    margin-left: 4px;
    background-color: #d0d0d3;
    /* box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); */
    border-radius: 50%;
}


/* Keyframes for the dropdown animation */
@keyframes dropdown-fade-in {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Show dropdown on hover */
.dropdown:hover .dropdown-menu {
    display: flex;
    flex-direction: column;
    animation: none;
    /* Remove the existing wiggle animation */
}

.dropdown-menu .dropdown-item {
    opacity: 0;
    /* Start hidden */
    animation: dropdown-fade-in 0.3s ease forwards;
}

/* Add delay for each item to create the staggered effect */
.dropdown-menu .dropdown-item:nth-child(1) {
    animation-delay: 0.1s;
}

.dropdown-menu .dropdown-item:nth-child(2) {
    animation-delay: 0.2s;
}

.dropdown-menu .dropdown-item:nth-child(3) {
    animation-delay: 0.3s;
}

.dropdown-menu .dropdown-item:nth-child(4) {
    animation-delay: 0.4s;
}

.dropdown-menu .dropdown-item:nth-child(5) {
    animation-delay: 0.5s;
}

.dropdown-menu .dropdown-item:nth-child(6) {
    animation-delay: 0.6s;
}

.dropdown-menu .dropdown-item:nth-child(7) {
    animation-delay: 0.7s;
}

.dropdown-menu .dropdown-item:nth-child(8) {
    animation-delay: 0.8s;
}

.dropdown-menu .dropdown-item:nth-child(9) {
    animation-delay: 0.9s;
}

.dropdown-menu .dropdown-item:nth-child(10) {
    animation-delay: 1s;
}

.dropdown-menu .dropdown-item:nth-child(11) {
    animation-delay: 1.1s;
}

.dropdown-menu .dropdown-item:nth-child(12) {
    animation-delay: 1.2s;
}

/* Continue with the same pattern if you have more items */



.dropdown-item {
    padding: 12px 16px;
    color: #333;
    text-align: left;
    text-decoration: none;
    width: 100%;
    transition: background-color 0.3s ease;
    position: relative;
}

.dropdown-item a {
    /* font-size: 10px; */
    font-size: 1rem;
}

/* Main dropdown item separation line */
.dropdown-item+.dropdown-item {
    border-top: 1px solid #e0e0e0; 
}

/* Sub-dropdown item separation line */
.sub-dropdown a+a {
    border-top: 1px solid #e0e0e0;
}

.dropdown-item:hover {
    background-color: #f1f1f1;
    /* transition: .3s; */
}

/* Sub-dropdown styling */
.sub-dropdown {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    min-width: 200px;
    z-index: 1000;
    white-space: nowrap;
    display: none;
    margin-left: 1px; /* small offset to avoid overlapping */
}

/* Show sub-dropdown on hover */
.dropdown-item:hover .sub-dropdown {
    display: block;
}


/* Keyframes for sub-dropdown animation */
@keyframes sub-dropdown-fade-in {
    from {
        opacity: 0;
        transform: translateX(-10px);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}


.sub-dropdown a {
    padding: 12px 16px;
    /* Match the padding of .dropdown-item */
    color: #1c1e21;
    text-decoration: none;
    display: block;
    font-size: 1rem;
    width: 100%;
    font-size: 1rem;
    opacity: 0;
    /* Start hidden */
    animation: sub-dropdown-fade-in 0.3s ease forwards;
    transition: background-color 0.3s ease;
}

/* Add delay for each item to create the staggered effect */
.sub-dropdown a:nth-child(1) {
    animation-delay: 0.1s;
}

.sub-dropdown a:nth-child(2) {
    animation-delay: 0.2s;
}

.sub-dropdown a:nth-child(3) {
    animation-delay: 0.3s;
}

.sub-dropdown a:nth-child(4) {
    animation-delay: 0.4s;
}

.sub-dropdown a:nth-child(5) {
    animation-delay: 0.5s;
}

.sub-dropdown a:nth-child(6) {
    animation-delay: 0.6s;
}

/* Continue adding nth-child selectors if you have more items */

.sub-dropdown a:hover {
    background-color: #f1f1f1;
}


/* Right part - Search Box */
.navbar-search {
    display: flex;
    align-items: center;
    gap: 8px;
}

.search-input {
    padding: 10px;
    border: 1px solid #ced4da;
    border-radius: 5px;
    outline: none;
}

.search-input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.search-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 9px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.search-button:hover {
    background-color: #0056b3;
}



/* Hamburger Icon */
.hamburger-icon {
    display: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #1c1e21;
}


/* ============== ============ Responsive adjustments ============= ============== */

@media (max-width: 1024px) {
    .navbar-container {
        margin: 0 20px;
    }
    
}



@media (max-width: 768px) {
    .dropdown-menu {
        top: calc(100% + 20px);
        
    }

    .navbar-section {
        padding: 5px 0;
    }

    .search-input {
        width: 105px;
        padding: 8px;

    }

    .navbar-logo img {
        height: 50px;
        width: auto;
    }

    .search-button {
        padding: 8px 10px;
        font-size: 0.8rem;
    }
}

@media (max-width: 425px){
    .navbar-links {
        position: fixed;
        top: 65px;
        /* Adjusted to open from the left side */
        left: -100%; 
        height: 100%;
        /* height: 50%; */
        /* width: 250px; */
        min-width: 250px;
        background-color: #ffffff;
        flex-direction: column;
        align-items: flex-start;
        padding-top: 15px;
        transition: left 0.3s ease-in-out;
        box-shadow: 2px 0 5px rgba(0,0,0,0.1);
        z-index: 999;
        border-bottom: 1px solid #0056b3;
    }

    /* .dropdown-link {
        margin-left: 20px;
    } */

    .dropdown-link span {
        margin-left: 5px;
    }

    .navbar-links.active {
        left: 0; /* Show menu when active */
    }

    .navbar-links a {
        padding: 0px 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .dropdown-menu {
        position: relative;
        top: 10px;
        left: 0;
        box-shadow: none;
        background-color: transparent;
        padding: 0;
        margin-left: 20px;
    }

    .dropdown-item {
        padding: 10px 20px;
        word-wrap: break-word; /* Wraps long text */
        white-space: normal; /* Allow text to wrap onto multiple lines */
        padding-left: 20px;
        flex-direction: column;
        min-width: 200px;
    }

    .sub-dropdown {
        position: relative;
        left: 0;
        top: 0;
        box-shadow: none;
        border: none;
        padding-left: 20px;
    }

    .sub-dropdown a {
        padding: 8px 0;
        text-align: left;
    }

    .navbar-search {
        display: none; /* Hide search box on small screens */
    }

    .hamburger-icon {
        display: block; /* Show hamburger icon on small screens */
    }

    .dropdown-item .sub-dropdown {
        display: none; /* Hidden by default */
    }

    .dropdown-item .sub-dropdown.active {
        display: block; /* Displayed only when active */
    }
}


