.contact-section {
    width: 100%;
    min-height: 100vh;
    background-image: url('../../images/Free-Consultation-2.jpg');
    /* background-size: contain; */
    background-size: cover; /* Changed from contain to cover */
    background-position: center center;
    background-repeat: no-repeat; /* Prevents the image from repeating */
    background-attachment: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px 20px;
}

.contact-container-home {
    width: 80%;
    max-width: 1200px;
    padding: 40px;
    border-radius: 8px;
    text-align: center;
}

.contact-container-home h2 {
    font-size: 2.5rem;
    margin-bottom: 50px;
    color: #fff;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.form-row {
    display: flex;
    gap: 20px;
}


/* Ensure consistent width for all input fields and select elements */
.form-row input,
.form-row select {
    flex: 1;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Increase message box height */
.contact-form textarea {
    resize: none;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    height: 130px; /* Adjust the height as needed */
}


/* Ensure CAPTCHA row inputs are aligned properly */
.captcha-row {
    display: flex;
    gap: 20px;
    width: 100%;
    flex: 1;
}

.captcha-row input {
    flex: 1;
}

.captcha-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    font-size: 1.5rem;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100px;
}

.contact-form button {
    width: 120px; /* Adjusted width for a shorter button */
    padding: 12px;
    font-size: 1.1rem;
    /* color: #fff; */
    color: #000;
    /* background-color: #007BFF; */
    background-color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin: 0 auto;
}

.contact-form button:hover {
    /* background-color: #0056b3; */
    background-color: #d2dde9;
}


.message {
    font-size: 1.1em;
    font-weight: bold;
    padding: 8px 12px;
    margin-top: 10px;
    display: inline-block;
    width: auto;
    border-radius: 5px;
}

/* Form Delivery messages */
.success {
    color: #2f6627;
    background-color: #d4edda;
}

.error {
    color: #721c24;
    background-color: #f8d7da;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }
}


/* Responsive adjustments for CAPTCHA input fields */
@media (max-width: 425px) {
    .captcha-row {
        flex-direction: column;
    }
    .captcha-row input {
        width: 100%;
        margin-bottom: 10px; /* Optional: add spacing between inputs */
    }

    .contact-container-home{
        padding: 0px;
    }
}